import { report_cameraService } from '../_services';

const state = { onRequest: false, report_camera: [] };

const actions = {
    get_data({ dispatch, commit }, data) {
        commit('request');

        report_cameraService.get_data(data)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    clear_data({ commit }) {
        commit('clear_data');
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  report_camera: state => {
    return state.report_camera
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.report_camera = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.report_camera = data;
    },
    clear_data(state) {
      state.onRequest = false;
      state.report_camera = [];
    },
    error(state) {
        state.onRequest = false;
    },
};

export const report_cameras = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

import { inventory_historiesService } from '../_services';

const state = { onRequest: false, inventory_histories: [] };

const actions = {
    get_inventory_histories({ dispatch, commit }, data) {
        commit('request');

        inventory_historiesService.get_inventory_histories(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  inventory_histories: state => {
    return state.inventory_histories
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.inventory_histories = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.inventory_histories = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.inventory_histories = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const inventory_histories = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

import { createStore } from 'vuex'
import { alert } from './alert.module';
import { users } from './users.module';
import { accounts } from './accounts.module';
import { vehicles } from './vehicles.module';
import { drivers } from './drivers.module';
import { gps } from './gps.module';
import { sensors } from './sensors.module';
import { vehicle_commands } from './vehicle_commands.module';
import { telegram_settings } from './telegram_settings.module';
import { sim_cards } from './sim_cards.module';
import { gps_cameras } from './gps_cameras.module';
import { ibuttons } from './ibuttons.module';
import { work_orders } from './work_orders.module';
import { routes } from './routes.module';
import { sub_routes } from './sub_routes.module';
import { alerts } from './alerts.module';
import { stops } from './stops.module';
import { trips } from './trips.module';
import { road_warrants } from './road_warrants.module';
import { trip_schedules } from './trip_schedules.module';
import { trip_histories } from './trip_histories.module';
import { vehicle_routes } from './vehicle_routes.module';
import { case_incidents } from './case_incidents.module';
import { route_targets } from './route_targets.module';
import { monitor_schematics } from './monitor_schematics.module';
import { monitor_vehicles } from './monitor_vehicles.module';
import { monitor_route_vehicles } from './monitor_route_vehicles.module';
import { live_maps } from './live_maps.module';
import { live_video_alerts } from './live_video_alerts.module';
import { live_video_streamings } from './live_video_streamings.module';
import { location_histories } from './location_histories.module';
import { history_vehicles } from './history_vehicles.module';
import { report_generates } from './report_generates.module';
import { history_sensors } from './history_sensors.module';
import { report_graph_sensors } from './report_graph_sensors.module';
import { report_history_video_alerts } from './report_history_video_alerts.module';
import { report_passenger_flows } from './report_passenger_flows.module';
import { journey_vehicles } from './journey_vehicles.module';
import { journey_drivers } from './journey_drivers.module';
import { driver_rankings } from './driver_rankings.module';
import { report_route_targets } from './report_route_targets.module';
import { report_cameras } from './report_cameras.module';
import { report_operational_vehicles } from './report_operational_vehicles.module';
import { report_stop_bus_times } from './report_stop_bus_times.module';
import { punctualities } from './punctualities.module';
import { expired_licenses } from './expired_licenses.module';
import { custom_event_alerts } from './custom_event_alerts.module';
import { checkpoint_vehicles } from './checkpoint_vehicles.module';
import { account_capabilities } from './account_capabilities.module';
import { user_capabilities } from './user_capabilities.module';
import { dashboards } from './dashboards.module';
import { checklist_models } from './checklist_models.module';
import { checklist_items } from './checklist_items.module';
import { checklist_users } from './checklist_users.module';
import { checklists } from './checklists.module';
import { deliveries } from './deliveries.module';
import { costs } from './costs.module';
import { vehicle_sharings } from './vehicle_sharings.module';
import { parts } from './parts.module';
import { inventories } from './inventories.module';
import { inventory_histories } from './inventory_histories.module';
import { maintenance_schedules } from './maintenance_schedules.module';
import { maintenance_reminders } from './maintenance_reminders.module';
import { part_mileages } from './part_mileages.module';
import { ceiba_servers } from './ceiba_servers.module';
import { ceiba_devices } from './ceiba_devices.module';
import { payment_links } from './payment_links.module';
import { sales } from './sales.module';
import { location_addresses } from './location_addresses.module';
import { insert_positions } from './insert_positions.module';
import { login_logs } from './login_logs.module';
import { driver_logins } from './driver_logins.module';
import { report_geofences } from './report_geofences.module';
import { report_geofence_summary_kms } from './report_geofence_summary_kms.module';
import { adjust_geofence_summary_kms } from './adjust_geofence_summary_kms.module';

export const store = createStore({
  modules: {
      alert,
      users,
      accounts,
      vehicles,
      drivers,
      gps,
      sensors,
      vehicle_commands,
      telegram_settings,
      sim_cards,
      gps_cameras,
      ibuttons,
      work_orders,
      routes,
      sub_routes,
      alerts,
      stops,
      trips,
      road_warrants,
      trip_schedules,
      trip_histories,
      vehicle_routes,
      case_incidents,
      route_targets,
      monitor_schematics,
      monitor_vehicles,
      monitor_route_vehicles,
      live_maps,
      live_video_alerts,
      live_video_streamings,
      location_histories,
      history_vehicles,
      report_generates,
      history_sensors,
      report_graph_sensors,
      report_history_video_alerts,
      report_passenger_flows,
      journey_vehicles,
      journey_drivers,
      driver_rankings,
      report_route_targets,
      report_cameras,
      report_operational_vehicles,
      report_stop_bus_times,
      punctualities,
      expired_licenses,
      custom_event_alerts,
      checkpoint_vehicles,
      account_capabilities,
      user_capabilities,
      dashboards,
      checklist_models,
      checklist_items,
      checklist_users,
      checklists,
      deliveries,
      costs,
      vehicle_sharings,
      parts,
      inventories,
      inventory_histories,
      maintenance_schedules,
      maintenance_reminders,
      part_mileages,
      ceiba_servers,
      ceiba_devices,
      payment_links,
      sales,
      location_addresses,
      insert_positions,
      login_logs,
      driver_logins,
      report_geofences,
      report_geofence_summary_kms,
      adjust_geofence_summary_kms,
  }
})

import { live_mapService } from '../_services';

const state = { onRequest: false, live_map: [] };

const actions = {
    get_data({ dispatch, commit }, data) {
        commit('request');

        live_mapService.get_data(data)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    clear_data({ commit }) {
        commit('clear_data');
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  live_map: state => {
    return state.live_map
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.live_map = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.live_map = data;
    },
    clear_data(state) {
      state.onRequest = false;
      state.live_map = [];
    },
    error(state) {
        state.onRequest = false;
    },
};

export const live_maps = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

import { maintenance_reminderService } from '../_services';

const state = { onRequest: false, maintenance_reminders: [], maintenance_reminder: {} };

const actions = {
    get_maintenance_reminder_by_account({ dispatch, commit }, data) {
        commit('request');

        maintenance_reminderService.get_maintenance_reminder_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  maintenance_reminders: state => {
    return state.maintenance_reminders
  },
  maintenance_reminder: state => {
    return state.maintenance_reminder
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.maintenance_reminder = {};
        state.maintenance_reminders = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.maintenance_reminder = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.maintenance_reminders = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const maintenance_reminders = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

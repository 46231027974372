import { maintenance_scheduleService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, maintenance_schedules: [], maintenance_schedule: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        maintenance_scheduleService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        maintenance_scheduleService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/maintenance_schedules');
                    dispatch('alert/success', "Maintenance Schedule Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/maintenance_schedules');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        maintenance_scheduleService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/maintenance_schedules');
                    dispatch('alert/success', "Maintenance Schedule Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/maintenance_schedules');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        maintenance_scheduleService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      maintenance_scheduleService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/maintenance_schedules');
                  dispatch('alert/success', "Maintenance Schedule Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/maintenance_schedules');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_maintenance_schedule_by_account({ dispatch, commit }, data) {
        commit('request');

        maintenance_scheduleService.get_maintenance_schedule_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_maintenance_schedule_by_vehicle({ dispatch, commit }, data) {
        commit('request');

        maintenance_scheduleService.get_maintenance_schedule_by_vehicle(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  maintenance_schedules: state => {
    return state.maintenance_schedules
  },
  maintenance_schedule: state => {
    return state.maintenance_schedule
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.maintenance_schedule = {};
        state.maintenance_schedules = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.maintenance_schedule = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.maintenance_schedules = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const maintenance_schedules = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

import { partService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, parts: [], part: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        partService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        partService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/parts');
                    dispatch('alert/success', "Part Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/parts');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        partService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/parts');
                    dispatch('alert/success', "Part Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/parts');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        partService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      partService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/parts');
                  dispatch('alert/success', "Part Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/parts');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    new_parts({ dispatch, commit }, data) {
        commit('request');

        partService.new_parts(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  parts: state => {
    return state.parts
  },
  part: state => {
    return state.part
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.part = {};
        state.parts = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.part = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.parts = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const parts = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

import { part_mileageService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, part_mileages: [], part_mileage: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        part_mileageService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        part_mileageService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/part_mileages');
                    dispatch('alert/success', "Part Mileage Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/part_mileages');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        part_mileageService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/part_mileages');
                    dispatch('alert/success', "Part Mileage Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/part_mileages');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        part_mileageService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      part_mileageService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/part_mileages');
                  dispatch('alert/success', "Part Mileage Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/part_mileages');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_part_mileage_by_account({ dispatch, commit }, data) {
        commit('request');

        part_mileageService.get_part_mileage_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  part_mileages: state => {
    return state.part_mileages
  },
  part_mileage: state => {
    return state.part_mileage
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.part_mileage = {};
        state.part_mileages = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.part_mileage = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.part_mileages = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const part_mileages = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

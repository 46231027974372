import { tripService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, trips: [], trip: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        tripService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        tripService.add(data)
            .then(
                async () => {
                    commit('success');
                    if(data.is_spda)
                        await router.push('/spdas');
                    else
                        await router.push('/trips');
                    dispatch('alert/success', "Trip Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    if(data.is_spda)
                        await router.push('/spdas');
                    else
                        await router.push('/trips');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add_bulks({ dispatch, commit }, data) {
        commit('request');

        tripService.add_bulks(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/trips');
                    dispatch('alert/success', "Trip Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/trips');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        tripService.edit(data)
            .then(
                async () => {
                    commit('success');
                    if(data.is_spda)
                        await router.push('/spdas');
                    else
                        await router.push('/trips');
                    dispatch('alert/success', "Trip Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');                    
                    if(data.is_spda)
                        await router.push('/spdas');
                    else
                        await router.push('/trips');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    adjust({ dispatch, commit }, data) {
        commit('request');

        tripService.adjust(data)
            .then(
                async () => {
                    commit('success');
                    if(data.is_spda)
                        await router.push('/spdas');
                    else
                        await router.push('/trips');
                    dispatch('alert/success', "Trip Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    if(data.is_spda)
                        await router.push('/spdas');
                    else
                        await router.push('/trips');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        tripService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      tripService.destroy(id)
          .then(
              async () => {
                  commit('success');      
                  await router.go();
                  dispatch('alert/success', "Trip Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go();
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_trip_by_date({ dispatch, commit }, data) {
        commit('request');

        tripService.get_trip_by_date(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_trip_by_account({ dispatch, commit }, data) {
        commit('request');

        tripService.get_trip_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  trips: state => {
    return state.trips
  },
  trip: state => {
    return state.trip
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.trip = {};
        state.trips = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.trip = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.trips = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const trips = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

import { telegram_settingService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, telegram_setting: null };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        telegram_settingService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        telegram_settingService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/telegram_settings');
                    dispatch('alert/success', "Telegram Setting Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/telegram_settings');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  telegram_setting: state => {
    return state.telegram_setting
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.telegram_setting = null;
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.telegram_setting = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const telegram_settings = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

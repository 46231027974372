import { route_targetService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, route_targets: [], route_target: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        route_targetService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        route_targetService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/route_targets');
                    dispatch('alert/success', "Case Incident Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/route_targets');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        route_targetService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/route_targets');
                    dispatch('alert/success', "Case Incident Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/route_targets');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        route_targetService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      route_targetService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/route_targets');
                  dispatch('alert/success', "Case Incident Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/route_targets');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_route_target_by_account({ dispatch, commit }, data) {
        commit('request');

        route_targetService.get_route_target_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  route_targets: state => {
    return state.route_targets
  },
  route_target: state => {
    return state.route_target
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.route_target = {};
        state.route_targets = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.route_target = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.route_targets = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const route_targets = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

import { trip_scheduleService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, trip_schedules: [], trip_schedule: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        trip_scheduleService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        trip_scheduleService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/trip_schedules');
                    dispatch('alert/success', "Trip Schedule Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/trip_schedules');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        trip_scheduleService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/trip_schedules');
                    dispatch('alert/success', "Trip Schedule Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/trip_schedules');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        trip_scheduleService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      trip_scheduleService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/trip_schedules');
                  dispatch('alert/success', "Trip Schedule Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/trip_schedules');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_trip_schedule_by_account({ dispatch, commit }, data) {
        commit('request');

        trip_scheduleService.get_trip_schedule_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  trip_schedules: state => {
    return state.trip_schedules
  },
  trip_schedule: state => {
    return state.trip_schedule
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.trip_schedule = {};
        state.trip_schedules = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.trip_schedule = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.trip_schedules = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const trip_schedules = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
